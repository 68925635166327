import React from 'react';
import tw from 'twin.macro';
import StructuredText from '@datocms/MyStructuredTextWithBlocks';
import type { StructuredTextField } from '@datocms/types';
import Header from '@sections/CenteredHeader';

const DotPatterns = () => {
	return (
		<>
			<svg
				tw="absolute top-12 left-full transform translate-x-32"
				width={404}
				height={384}
				fill="none"
				viewBox="0 0 404 384">
				<defs>
					<pattern
						id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
						x={0}
						y={0}
						width={20}
						height={20}
						patternUnits="userSpaceOnUse">
						<rect
							x={0}
							y={0}
							width={4}
							height={4}
							tw="text-gray-200"
							fill="currentColor"
						/>
					</pattern>
				</defs>
				<rect
					width={404}
					height={384}
					fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
				/>
			</svg>
			<svg
				tw="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
				width={404}
				height={384}
				fill="none"
				viewBox="0 0 404 384">
				<defs>
					<pattern
						id="f210dbf6-a58d-4871-961e-36d5016a0f49"
						x={0}
						y={0}
						width={20}
						height={20}
						patternUnits="userSpaceOnUse">
						<rect
							x={0}
							y={0}
							width={4}
							height={4}
							tw="text-gray-200"
							fill="currentColor"
						/>
					</pattern>
				</defs>
				<rect
					width={404}
					height={384}
					fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
				/>
			</svg>
			<svg
				tw="absolute bottom-12 left-full transform translate-x-32"
				width={404}
				height={384}
				fill="none"
				viewBox="0 0 404 384">
				<defs>
					<pattern
						id="d3eb07ae-5182-43e6-857d-35c643af9034"
						x={0}
						y={0}
						width={20}
						height={20}
						patternUnits="userSpaceOnUse">
						<rect
							x={0}
							y={0}
							width={4}
							height={4}
							tw="text-gray-200"
							fill="currentColor"
						/>
					</pattern>
				</defs>
				<rect
					width={404}
					height={384}
					fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
				/>
			</svg>
		</>
	);
};

export type ContentCenteredProps = {
	supertitle?: string;
	title: string;
	description?: StructuredTextField;
	content: StructuredTextField;
	hrefId: string;
} & React.ComponentProps<'div'>;

export const ContentCentered = ({
	title,
	supertitle,
	description,
	content,
	hrefId,
	...divProps
}: ContentCenteredProps) => {
	return (
		<div tw="relative py-16 overflow-hidden" {...divProps}>
			<div tw="hidden lg:(block absolute inset-y-0 h-full w-full)">
				<div
					tw="relative h-full text-lg max-w-prose mx-auto"
					aria-hidden="true">
					<DotPatterns />
				</div>
			</div>
			<div tw="relative px-4 sm:px-6 lg:px-8">
				<div tw="text-lg max-w-prose mx-auto space-y-6">
					<Header
						hrefId={hrefId}
						supertitle={supertitle}
						title={title}
						description={description}
					/>
					<article css={tw`prose prose-lg text-gray-500`}>
						<StructuredText data={content} />
					</article>
				</div>
			</div>
		</div>
	);
};

export default ContentCentered;
