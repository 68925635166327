import { useRouter } from 'next/router';
import React, { useCallback } from 'react';
import tw, { css, styled } from 'twin.macro';
import DatoLink from '@datocms/Link';
import StructuredText from '@datocms/MyStructuredText';
import type { StructuredTextField, LinkBlock } from '@datocms/types';
import StyledSentence from '@styled/Sentence';
import Heroicon from 'components/Heroicon';
import Link from 'components/SmartLink';
import { outerContainer } from 'styles';

// adapted from https://tailwindui.com/components/marketing/sections/heroes#component-b9bcab4538776a17fff93d18f82a8272

const SideDotsPattern = () => (
	<>
		<svg
			tw="absolute right-full transform translate-y-1/4 translate-x-1/4 lg:translate-x-1/2"
			width={404}
			height={784}
			fill="none"
			viewBox="0 0 404 784">
			<defs>
				<pattern
					id="f210dbf6-a58d-4871-961e-36d5016a0f49"
					x={0}
					y={0}
					width={20}
					height={20}
					patternUnits="userSpaceOnUse">
					<rect
						x={0}
						y={0}
						width={4}
						height={4}
						tw="text-gray-200"
						fill="currentColor"
					/>
				</pattern>
			</defs>
			<rect
				width={404}
				height={784}
				fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
			/>
		</svg>
		<svg
			tw="absolute left-full transform -translate-y-3/4 -translate-x-1/4 md:-translate-y-1/2 lg:-translate-x-1/2"
			width={404}
			height={784}
			fill="none"
			viewBox="0 0 404 784">
			<defs>
				<pattern
					id="5d0dd344-b041-4d26-bec4-8d33ea57ec9b"
					x={0}
					y={0}
					width={20}
					height={20}
					patternUnits="userSpaceOnUse">
					<rect
						x={0}
						y={0}
						width={4}
						height={4}
						tw="text-gray-200"
						fill="currentColor"
					/>
				</pattern>
			</defs>
			<rect
				width={404}
				height={784}
				fill="url(#5d0dd344-b041-4d26-bec4-8d33ea57ec9b)"
			/>
		</svg>
	</>
);

const ctaBase = tw`w-full flex items-center justify-center px-8 py-3 text-base text-center font-semibold rounded-lg md:(py-4 text-lg px-10)`;
const ctaFocus = tw`focus:(outline-none ring-4 ring-offset-2 ring-brand-500)`;

const ctaPrimaryStyle: CSS = {
	...ctaBase,
	...tw`bg-primary text-white shadow-md border border-transparent shadow-secondary`,
	...ctaFocus,
	...tw`hover:(bg-brand-800)`,
};

const ctaSecondaryStyle: CSS = {
	...ctaBase,
	...tw`bg-white text-primary shadow-md border border-transparent`,
	...ctaFocus,
	...tw`hover:(bg-gray-50)`,
};

type CtasProps = {
	ctas: LinkBlock[];
};
const Ctas = ({ ctas: [primary, secondary] }: CtasProps) => {
	const {
		description: _,
		heroicon: primaryIcon,
		...primaryLinkProps
	} = primary ?? {};
	const {
		description: __,
		heroicon: secondaryIcon,
		...secondaryLinkProps
	} = secondary ?? {};

	return (
		<>
			{primary && (
				<DatoLink {...primaryLinkProps} css={ctaPrimaryStyle}>
					{primaryIcon && (
						<Heroicon tw="mr-1.5 w-5 h-5" type="solid" heroicon={primaryIcon} />
					)}
					{primary.name}
				</DatoLink>
			)}
			{secondary && (
				<DatoLink {...secondaryLinkProps} css={ctaSecondaryStyle}>
					{secondaryIcon && (
						<Heroicon
							tw="mr-1.5 w-5 h-5"
							type="solid"
							heroicon={secondaryIcon}
						/>
					)}
					{secondary.name}
				</DatoLink>
			)}
		</>
	);
};

type SimpleCenteredHeroProps = {
	autoLinkTitle?: boolean;
	title: string;
	description?: StructuredTextField;
	ctas: LinkBlock[];
	afterDescriptionNode?: React.ReactNode;
	afterCtasNode?: React.ReactNode;
};

const SimpleCenteredHero = ({
	autoLinkTitle,
	title,
	description,
	afterDescriptionNode,
	ctas = [],
	afterCtasNode,
}: SimpleCenteredHeroProps) => {
	const AutoLinkedTitle = useCallback(
		styled(autoLinkTitle ? Link : 'span', {
			...tw`outline-none block xl:inline-block`,
		}),
		[autoLinkTitle]
	);
	const { locale, defaultLocale, asPath } = useRouter();
	const [path] = asPath.split(/[?#]/);

	const pathWithLocale = locale === defaultLocale ? path : `/${locale}${path}`;
	return (
		<section tw="relative bg-gray-50 overflow-hidden border-b border-gray-200">
			{/* left and right dot pattern   */}
			<div
				tw="hidden sm:block sm:absolute sm:inset-y-0 sm:h-full sm:w-full"
				aria-hidden="true">
				<div css={outerContainer} tw="relative h-full">
					<SideDotsPattern />
				</div>
			</div>
			{/* hero container */}
			<div tw="relative py-16 sm:py-24">
				<div css={outerContainer} tw="px-4">
					<div tw="text-center">
						{/* title */}
						<h1 tw="break-words text-4xl tracking-tight font-extrabold sm:text-5xl md:text-6xl">
							<AutoLinkedTitle
								href={autoLinkTitle ? pathWithLocale : undefined}>
								<StyledSentence
									n={0.65}
									twFirstWords={tw`text-primary`}
									twLastWords={tw`text-secondary`}>
									{title}
								</StyledSentence>
							</AutoLinkedTitle>
						</h1>
						{/* optional description   */}
						{description && (
							<div tw="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:(mt-5 text-xl max-w-3xl)">
								<StructuredText data={description} />
							</div>
						)}
					</div>
					{afterDescriptionNode || <></>}
					{/* optional call to actions */}
					{ctas.length > 0 && (
						<div
							className="cta"
							tw="mt-8 max-w-lg mx-auto -sm:(space-y-3) sm:(flex justify-center space-x-3)">
							<Ctas ctas={ctas} />
						</div>
					)}
					{afterCtasNode || <></>}
				</div>
			</div>
		</section>
	);
};

export default SimpleCenteredHero;
