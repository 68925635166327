import React from 'react';
import Content from '@sections/Content/Centered';
import FAQDisclosable from '@sections/FAQ/Disclosable';
import FAQDisclosed from '@sections/FAQ/DisclosedOffset';
import Prose from './Prose';
import SideBySideTestimonial from './Testimonial/SideBySide';

type PageSectionProps = {
	dato: Record<string, any>;
} & React.ComponentProps<'section'>;

const PageSection = ({
	dato: { __typename, ...data },
	...sectionProps
}: PageSectionProps) => {
	let sectionElement = <></>;
	const hrefId = data.fragment || data.id;
	if (__typename === 'LinkedFaqSectionRecord') {
		const props = {
			hrefId,
			supertitle: data.supertitle || data.faq.name,
			title: data.title,
			description: data.description,
			items: data.faq.items,
		};
		sectionElement = data?.disclosableItems ? (
			<FAQDisclosable {...props} />
		) : (
			<FAQDisclosed {...props} />
		);
	}
	if (__typename === 'ContentSectionRecord') {
		sectionElement = (
			<Content
				hrefId={hrefId}
				supertitle={data.supertitle}
				title={data.title}
				description={data.description}
				content={data.content}
			/>
		);
	}
	if (__typename === 'TestimonialSideBySideSectionRecord') {
		const props = {
			testimonials: data.testimonials.map(({ __typename, ...testimonial }) => {
				if (__typename === 'TestimonialRecord') {
					const companyLogo =
						testimonial?.companyLogo?.format === 'svg'
							? testimonial?.companyLogo?.url
							: '';
					const companyLogoAlt = testimonial?.companyLogo?.alt || 'Company';

					return {
						...testimonial,
						companyLogo,
						companyLogoAlt,
					};
				}
			}),
		};

		sectionElement = <SideBySideTestimonial {...props} />;
	}
	if (__typename === 'ProseRecord') {
		const props = {
			content: data.content,
		};
		sectionElement = <Prose {...props} />;
	}

	return (
		<section {...sectionProps} id={hrefId}>
			{sectionElement}
		</section>
	);
};

export default PageSection;
