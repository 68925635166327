/* https://tailwindui.com/components/marketing/sections/testimonials#component-457ad469e750400bb907272fd68b4561 */

import { splitEvery } from 'ramda';
import React, { useCallback } from 'react';
import { Image } from 'react-datocms';
import { ReactSVG } from 'react-svg';
import tw, { styled } from 'twin.macro';
import type { ResponsiveImageField } from '@datocms/types';
import Link from 'components/SmartLink';

type Testimonial = {
	index: number;
	id: string;
	source: string;
	quote: string;
	subtitle: string;
	sourceUrl: string;
	companyUrl: string;
	companyLogo: string;
	companyLogoAlt: string;
	avatar?: ResponsiveImageField;
};

const QuoteIcon = (props: React.ComponentProps<'svg'>) => {
	return (
		<svg {...props} fill="currentColor" viewBox="0 0 32 32" aria-hidden="true">
			<path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
		</svg>
	);
};
const Testimonial: React.FC<Testimonial> = ({
	id,
	source,
	quote,
	subtitle,
	index,
	sourceUrl,
	avatar,
	companyLogo,
	companyUrl,
	companyLogoAlt,
}) => {
	const both = tw`py-12 px-4 sm:px-6 md:py-16`;
	const even = tw`md:(pl-0 pr-10 flex flex-col) lg:pr-16`;
	const uneven = tw`md:(pr-0 pl-10) lg:pl-16`;

	const SourceContainer = useCallback(
		styled(sourceUrl ? Link : 'div', tw`inline-block align-middle`),
		[sourceUrl]
	);
	return (
		<div id={id} css={{ ...both, ...(index % 2 === 0 ? even : uneven) }}>
			<div tw="h-12 w-56 md:flex-shrink-0">
				{companyLogo && companyUrl && (
					<Link href={companyUrl}>
						<span tw="sr-only">{companyLogoAlt}</span>
						<ReactSVG
							css={{
								svg: {
									...tw`h-12 w-56 text-brand-300`,
								},
							}}
							src={companyLogo}
						/>
					</Link>
				)}
			</div>
			<blockquote tw="mt-6 md:flex-grow md:flex md:flex-col">
				<div tw="relative text-lg font-medium text-white md:flex-grow">
					<QuoteIcon tw="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-brand-500" />
					<p tw="relative">{quote}</p>
				</div>
				<footer tw="mt-8">
					<div tw="flex items-start space-x-4">
						{avatar && (
							<div tw="flex-shrink-0 inline-flex height[3.75rem] width[3.75rem] rounded-full border-2 border-gray-200">
								<Image tw="rounded-full" data={avatar.responsiveImage} />
							</div>
						)}
						<SourceContainer href={sourceUrl || undefined}>
							<div tw="text-base font-medium text-gray-200">{source}</div>
							{subtitle && (
								<div tw="text-base font-medium text-brand-300">{subtitle}</div>
							)}
						</SourceContainer>
					</div>
				</footer>
			</blockquote>
		</div>
	);
};

type Props = {
	testimonials: Testimonial[];
};
const SideBySideTestimonial: React.FC<Props & React.ComponentProps<'div'>> = ({
	testimonials,
	...divProps
}) => {
	const chunkedTestimonials = splitEvery(2, testimonials);
	return (
		<div tw="bg-brand-800" {...divProps}>
			<div tw="max-w-7xl mx-auto md:px-6 lg:px-8 divide-y divide-brand-500">
				{chunkedTestimonials.map((testimonials) => {
					return (
						<div
							key={testimonials.map((testimonial) => testimonial.id).join('')}
							tw="md:px-6 lg:px-8 space-y-6">
							<div tw="md:grid md:grid-cols-2 -md:divide-y md:(divide-x) divide-brand-500 ">
								{testimonials.map((testimonial, index) => {
									return (
										<Testimonial
											key={testimonial.id}
											{...testimonial}
											index={index}
										/>
									);
								})}
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default SideBySideTestimonial;
