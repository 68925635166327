import React from 'react';
import tw from 'twin.macro';
import { linkFocusRing } from '@styled/Link';
import Link from 'components/SmartLink';

export const Title = tw.h2`text-3xl font-extrabold text-gray-900`;
export const Description = tw.div`text-lg text-gray-600`;
export const Container = tw.div`space-y-5 sm:space-y-4`;

const OffsetHeader = ({ title, description, hrefId }) => {
	return (
		<Container>
			<Title>
				<Link css={linkFocusRing} href={`#${hrefId}`}>
					{title}
				</Link>
			</Title>
			<Description>{description}</Description>
		</Container>
	);
};

export default OffsetHeader;
