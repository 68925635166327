import SimpleHero from './SimpleCentered';

type HeroSectionProps = {
	autoLinkTitle?: boolean;
	dato: Record<string, any>;
};

const HeroSection = ({
	autoLinkTitle,
	dato: { __typename, ...data },
}: HeroSectionProps) => {
	let hero = <></>;
	if (__typename === 'SimpleHeroRecord') {
		const props = {
			title: data.title,
			description: data.description,
			ctas: data.ctas ?? [],
		};
		hero = <SimpleHero autoLinkTitle={autoLinkTitle} {...props} />;
	}
	return hero;
};

export default HeroSection;
